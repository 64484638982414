import React from "react";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Button, Card } from "@nextui-org/react";
import { NavLink, Outlet } from "react-router-dom";
import AppLogo from "../icons/logo";
import { ThemeSwitcher } from "../components/themeSwitcher";

export function AppLayout() {

  const navItems = [
    { title: 'WORK', href: '/work' },
    // { title: 'ABOUT', href: '/about' },
  ]

  return (
    <div className="flex flex-col h-screen">
      <Navbar isBordered>
        <NavbarBrand>
          <NavLink to="/">
          {({ isActive }) => (
            <Button variant="flat" isIconOnly as={Card}  className="shadow-none" color={isActive ? "primary" : "default"}>
              <AppLogo />
            </Button>
          )}
          </NavLink>
        </NavbarBrand>
        <NavbarContent justify="end" className="gap-1">
          {navItems.map((item) => (
            <NavbarItem key={item.title}>
              <NavLink to={item.href}>
                {({ isActive }) => (
                  <Button color={isActive ? "primary" : "default"} variant="flat" radius="medium" className="shadow-none font-medium" as={Card}>
                    {item.title}
                  </Button>
                )}
              </NavLink>
            </NavbarItem>)
          )}
          <NavbarItem>
            <ThemeSwitcher />
          </NavbarItem>
        </NavbarContent>
      </Navbar>
      <div className="w-full max-w-screen-lg mx-auto flex-1 flex flex-col">
        <Outlet />
      </div>
    </div>
  );
}

export default AppLayout;