export const PROJECT_TYPES = {
    ANDROID_APP: "Android App",
    WEBSITE: "Website"
}

const PROJECTS = [
    {
        id: "birthdays",
        iconURL: "https://play-lh.googleusercontent.com/B8lN84_GrJuzsL1WWt7oZZUmwFKObHCj_lWgOMZNzrW1iWHrB-HZueymDaafxx2BAg=s180-rw",
        title: "Birthdays & Wishes",
        type: PROJECT_TYPES.ANDROID_APP,
        description: "Android App for Birthday Reminder and wishing. Developed using SQLite database and Firebase realtime database.Published on Google Play Store.",
        links: [
            {
                url: "https://play.google.com/store/apps/details?id=com.rekhansh.birthdaycalendar",
                title: "Play Store",
            }
        ],
        technologies: []
    },
    {
        id: "quiz",
        iconURL: "https://play-lh.googleusercontent.com/fHTKKPaXPzbZgHF7lwF_IQxgJjthwXZqOSf_4lhk9eeHhgiyHJF_Q0n4ik9EWJIo59lU=s180-rw",
        title: "Quiz",
        type: PROJECT_TYPES.ANDROID_APP,
        description: "Android App for Birthday Reminder and wishing. Developed using SQLite database and Firebase realtime database.Published on Google Play Store.",
        links: [
            {
                url: "https://play.google.com/store/apps/details?id=com.rekhansh.quiz",
                title: "Play Store",

            }
        ],
        technologies: []
    },
    {
        id: "qr",
        iconURL: "https://play-lh.googleusercontent.com/PzbUEz6HZlkAB18BCaCUB_zSSsTVijzW5VwO2AxEaaYkltnwgb8uZNuWnoLKckAceg=s180-rw",
        title: "QR Scanner & Generator",
        type: PROJECT_TYPES.ANDROID_APP,
        description: "Android App for Birthday Reminder and wishing. Developed using SQLite database and Firebase realtime database.Published on Google Play Store.",
        links: [
            {
                url: "https://play.google.com/store/apps/details?id=com.rekhansh.qrscanner",
                title: "Play Store",

            }
        ],
        technologies: []
    },
    {
        id: "tapmax",
        iconURL: "https://play-lh.googleusercontent.com/jZ9TtQ4Y694plhEXxQvnd9nAD3UGvk9P3iTpXm33HNBtZdb-sgBcScN8shGTj4Id0g=s180-rw",
        title: "TapMax",
        type: PROJECT_TYPES.ANDROID_APP,
        description: "Android App for Birthday Reminder and wishing. Developed using SQLite database and Firebase realtime database.Published on Google Play Store.",
        links: [
            {
                url: "https://play.google.com/store/apps/details?id=com.rekhansh.tapmax",
                title: "Play Store",

            }
        ],
        technologies: []
    },
    {
        id: "numbershuffle",
        iconURL: "https://play-lh.googleusercontent.com/bzrlKP-ajC2cfXVWI7eXf1xcX7xJ2xzqexv_fO9Tk0Kwcu_xefiHMXiF0uju7pwr4oa2=s180-rw",
        title: "Number Shuffle",
        type: PROJECT_TYPES.ANDROID_APP,
        description: "Android App for Birthday Reminder and wishing. Developed using SQLite database and Firebase realtime database.Published on Google Play Store.",
        links: [
            {
                url: "https://play.google.com/store/apps/details?id=com.rekhansh.numbershuffle",
                title: "Play Store",

            }
        ],
        technologies: []
    },
    {
        id: "journalpanacea",
        iconURL: "http://www.journalpanacea.com/images/logo.png",
        title: "Panacea",
        type: PROJECT_TYPES.WEBSITE,
        description: "",
        links: [
            // {
            //   url: "http://www.journalpanacea.com/",
            //   title: "Play Store",
            //   
            // }
        ],
        technologies: []
    }
]

export function getProject(id) {
    const project = PROJECTS.find(p => p.id === id);
    return project;
}

export function getProjects(type) {
    const projects = PROJECTS.filter(p => p.type === type);
    return projects;
}