import { Button, Link } from "@nextui-org/react";
import { InstagramIcon } from "../../icons/instagramIcon";
import { LinkedInIcon } from "../../icons/linkedinIcon";
import { GitHubIcon } from "../../icons/githubIcon";

const links = [
    {
        icon: LinkedInIcon,
        label: "LinkedIn",
        url: "https://www.linkedin.com/in/rekhansh-singhwal/"
    },
    {
        icon: GitHubIcon,
        label: "GitHub",
        url: "https://github.com/rekhansh"
    },
    {
        icon: InstagramIcon,
        label: "Instagram",
        url: "https://www.instagram.com/rekhansh_dev/"
    }
];

function Home() {
    return (
        <>
            <div className="flex flex-col items-center py-3 my-auto">
                <div>Hi, my name is</div>
                <div className="font-medium text-3xl py-3">Rekhansh Singhwal</div>
                <div className="font-medium text-xl">I'm a Full Stack Developer</div>
            </div>
            <div className="flex gap-2 items-center p-2 m-0 mx-auto">
                {links.map(l => (
                    <Button isIconOnly aria-label={l.label} href={l.url} as={Link} isExternal variant="light">
                        <l.icon size={30} />
                    </Button>
                ))}
            </div>
        </>
    );
}

export default Home;