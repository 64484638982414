import { Button } from "@nextui-org/react";
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";
import { SunIcon } from "../icons/sunIcon";
import { MoonIcon } from "../icons/moonIcon";

export function ThemeSwitcher() {
  const [mounted, setMounted] = useState(false)
  const { theme, setTheme } = useTheme('light')

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return null

  return (
    // <Switch
    //   isSelected={theme=="light"}
    //   startContent={<SunIcon />}
    //   endContent={<MoonIcon />}
    //   onValueChange={(isSelected) => setTheme(isSelected ? 'light' : 'dark')}
    // ></Switch>
    <Button
      onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
      variant="flat"
      isIconOnly>
      {theme === 'light' ? <SunIcon /> : <MoonIcon />}
    </Button>
  )
};