import { createBrowserRouter } from "react-router-dom";
import AppLayout from "./layout";
import Home from "./pages/home";
// import About from "./pages/about";
import Error404 from "./pages/error404";
import Work from "./pages/work";

const router = createBrowserRouter([
    {
        path: "/",
        element: <AppLayout />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            // {
            //     path: "books/:bookId",
            //     element: <BookDetail />,
            //     loader: bookLoader,
            //     errorElement: <BookError />
            // },
            {
                path: "work",
                element: <Work />
            },
            // {
            //     path: "about",
            //     element: <About />
            // },
            {
                path: "*",
                element: <Error404 />,
            },
        ]
    },
]);

export default router;