
function Error404() {
    return (
        <div className="flex flex-col h-full">
            <div className="py-3 m-auto">
                ERROR 404
            </div>
        </div>
    );
}

export default Error404;