import { Card, CardBody, Image, Button, Link } from "@nextui-org/react";
import { PROJECT_TYPES, getProjects } from "../../constants/projects";

function Work() {
    return (
        <div className="px-4">
            {Object.keys(PROJECT_TYPES).map(type => (
                <div key={type}>
                    <div className="py-4 font-medium">{PROJECT_TYPES[type]}</div>
                    <div className="grid gap-2 pb-2 grid-cols-1 md:grid-cols-2">
                        {getProjects(PROJECT_TYPES[type]).map(project => (
                            <Card key={project.id} isBlurred isHoverable shadow="sm">
                                <CardBody>
                                    <div className="flex gap-4">
                                        <Image src={project.iconURL} className="w-full object-cover h-[48px]" />
                                        <div className="flex-1 flex flex-col">
                                            <div className="flex flex-col gap-0">
                                                <h2 className="font-semibold text-foreground/90">{project.title}</h2>
                                                <p className="text-small text-foreground/80">{project.type}</p>
                                            </div>
                                            <span className="flex-1" />
                                            <div className="flex flex-col items-end">
                                                {project.links.map(l => (
                                                    <Button key={l.title} showAnchorIcon variant="flat" href={l.url} target="_blank" as={Link}>
                                                        {l.title}
                                                    </Button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        ))}
                    </div>
                </div>
            ))}

        </div>
    );
}

export default Work;